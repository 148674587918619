export enum UserRole {
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
  CLIENT = 'CLIENT',
  PM = 'PM',
}
export type UserProfile = {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  isDeleted: false;
  password: string;
  role: UserRole;
  userInfo: {
    clientId: string | null;
    tenantId: string;
    _id: string;
  };
};
