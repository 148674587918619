import React, { FC } from 'react';

import { FormikErrorMessageProps } from './FormikErrorMessage.props';
import { ErrorMessage } from 'formik';

const FormikErrorMessageView: FC<FormikErrorMessageProps> = ({ name }) => {
  return (
    <ErrorMessage
      className="text-red-500 text-sm p-0 m-0"
      component={'div'}
      name={name}
    />
  );
};

export default FormikErrorMessageView;
