import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { OrderFormViewProps } from './OrderForm.props';
import PageLayout from 'components/layouts/PageLayout';
import Button from 'components/primitives/Button';
import Header from 'components/layouts/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, useFormikContext } from 'formik';

import TabGeneralInfo from './tabs/TabGeneralInfo';
import TabItemsToShip from './tabs/TabItemsToShip';
import TabAccountInfo from './tabs/TabAccountInfo';
import { RoutePaths } from 'constants/routes';
import Loading from 'components/primitives/Loading';
import { ORDER_FORM_LOCAL_STORAGE_KEY } from 'utils/shipOrder';
import Toast from 'helperClasses/toastHelper';
import { classes } from 'utils/tailwind';

const Tabs = {
  GENERAL_INFO: 'General Info',
  ITEMS_TO_SHIP: 'Items To Ship',
  ACCOUNT_INFO: 'Account Info',
};

type TabData = {
  name: string;
  component: ReactNode;
};

const OrderFormView: FC<OrderFormViewProps> = ({
  title,
  initialValues,
  onSubmit,
  mode = 'create',
  loading,
  saveForm,
  values,
  disabledTabIndex = new Set(),
  ...props
}) => {
  const navigate = useNavigate();
  const form = useFormikContext();
  const tabs: TabData[] = [
    { name: Tabs.GENERAL_INFO, component: <TabGeneralInfo mode={mode} /> },
    {
      name: Tabs.ITEMS_TO_SHIP,
      component: (
        <TabItemsToShip mode={mode} status={initialValues.orderStatus} />
      ),
    },
    { name: Tabs.ACCOUNT_INFO, component: <TabAccountInfo mode={mode} /> },
  ];

  // URL Navigation Logic
  const TAB_NAMES = ['general-info', 'items-to-ship', 'account-info'];
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') || TAB_NAMES[0];
  const tabIndex = TAB_NAMES.indexOf(tabParam);
  const [activeTab, setActiveTab] = useState(tabIndex >= 0 ? tabIndex : 0);

  const updateSearchParams = (updates: Record<string, string | undefined>) => {
    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      Object.entries(updates).forEach(([key, value]) => {
        if (value !== undefined) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      });
      return newParams;
    });
  };

  const handleTabClick = async (selectedTab: number) => {
    const errors = await props.validateForm();
    const hasErrors = Object.keys(errors).length > 0;
    form.setTouched(errors, true);

    if (hasErrors && selectedTab > activeTab) {
      Toast.error('Please fill all the required fields in the current tab.');
      return;
    }

    const tabName = TAB_NAMES[selectedTab];
    setActiveTab(selectedTab);
    updateSearchParams({ tab: tabName });
  };

  useEffect(() => {
    // Sync URL with activeTab
    const tabIndexFromURL = TAB_NAMES.indexOf(tabParam);
    if (tabIndexFromURL >= 0 && tabIndexFromURL !== activeTab) {
      setActiveTab(tabIndexFromURL);
    }
    saveForm && saveForm(values);
  }, [tabParam, activeTab, saveForm, values]);

  const handleCancel = useCallback(() => {
    localStorage.removeItem(ORDER_FORM_LOCAL_STORAGE_KEY);
    navigate(RoutePaths.shiporders);
  }, [saveForm]);

  const titleAsButton = () => {
    return (
      <button
        onClick={() => navigate('/ship-orders')}
        className="btn btn-ghost hover:bg-transparent focus:bg-transparent text-secondary">
        <span className="material-icons">keyboard_backspace</span> {title}
      </button>
    );
  };

  return (
    <>
      <Form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}>
        <>
          <Header title={titleAsButton()}>
            <div className="flex-1 justify-end">
              <Button
                variant="outline"
                type="button"
                className="rounded-btn border-blue-200 text-blue-200 btn-wide font-jakarta text-md normal-case"
                onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                onClick={async () => {
                  const errors = await props.validateForm();
                  const hasErrors = Object.keys(errors).length > 0;

                  if (hasErrors) {
                    Toast.error('Please fill all required fields.');
                    form.setTouched(errors, true);
                    return;
                  }

                  if (activeTab < tabs.length - 1) {
                    // Move to the next tab if not on the last tab
                    const nextTab = activeTab + 1;
                    setActiveTab(nextTab);
                    updateSearchParams({ tab: TAB_NAMES[nextTab] });
                  } else {
                    // Submit the form if on the last tab
                    await form.submitForm();
                  }
                }}
                isLoading={loading}
                type={activeTab === tabs.length - 1 ? 'submit' : 'button'}
                variant="solid"
                className="mx-2 rounded-btn border-blue-200 font-jakarta btn-wide text-md normal-case">
                {activeTab < tabs.length - 1 ? (
                  'Continue'
                ) : (
                  <>
                    {mode === 'create' && 'CREATE TICKET'}
                    {mode === 'process' && 'PROCESS ORDER'}
                    {mode === 'ship' && 'MARK ORDER SHIPPED'}
                    {mode === 'edit' && 'Update'}
                    {mode === 'update' && 'Update'}
                  </>
                )}
              </Button>
            </div>
          </Header>
          <PageLayout className="items-center gap-5 mt-10">
            <div
              role="tablist"
              className={classes([
                'tabs tabs-bordered text-tertiary',
                loading ? 'pointer-events-none' : '',
              ])}>
              {tabs.map((tab, index) => (
                <a
                  key={index}
                  role="tab"
                  className={`font-monsterrat ${
                    disabledTabIndex.has(index) ? 'pointer-events-none' : ''
                  } relative px-12 tab ${
                    activeTab === index
                      ? 'tab-active text-primary-350 font-semibold'
                      : 'text-primary-150'
                  }`}
                  onClick={() => handleTabClick(index)}>
                  {tab.name}
                  {props.validationCount[index] > 0 && (
                    <span className="absolute top-0 left-3 bg-red-500 w-5 h-5 rounded-xl text-xs text-white font-semibold">
                      {props.validationCount[index]}
                    </span>
                  )}
                </a>
              ))}
            </div>
            {tabs[activeTab].component}
          </PageLayout>
        </>
      </Form>
    </>
  );
};

export default OrderFormView;
