import React, { FC, useState } from 'react';

import { ItemsToShipViewProps } from './ItemsToShip.props';

import SearchBar from 'components/primitives/SearchBar';
import Input from 'components/primitives/Input';
import StatusBadge from 'components/primitives/StatusBadge';
import SearchInput from '../SearchInput';
import { fetchItems } from 'services/items';
import Delete from 'svg/delete';
import { useField, useFormikContext } from 'formik';
import Text from 'components/primitives/Text';
import { OrderStatus } from 'types/shipOrders';
import ZoomableImage from 'components/primitives/ZoomableImage/ZoomableImage';
import alertIcon from 'assets/SVG/alert.svg';
import { sortOrderItems } from 'utils/sortOrderItems';
import { useNavigate, useParams } from 'react-router-dom';
import AdjustStockContainer from 'routes/AdjustStock';
import AdjustStockView from 'routes/AdjustStock/AdjustStock.view';
import AdjustStockModalContainer from 'routes/AdjustStock/AdjustStockModal.container';
import useEditShipOrder from 'mutations/useEditShipOrder';
import { toApiPayloadDateFormat } from 'utils/date';
import CloseIcon from 'assets/SVG/close';

const ItemsToShipView: FC<ItemsToShipViewProps> = ({
  project,
  orderItems,
  onSearchResultSelect,
  handleQuantityChange,
  handleDelete,
  mode = 'create',
  status,
}) => {
  const isProcessed = status === OrderStatus.PROCESSED;

  const [errorMessage, meta] = useField('orderDetail');
  const [field] = useField('client');
  const { value: clientId } = field;
  const navigate = useNavigate();
  const triggerAdjustQuantity = {};

  const { id } = useParams();

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = (e: React.MouseEvent, itemId: any) => {
    e.preventDefault();
    setSelectedItemId(itemId);
    setModalOpen(true);
  };

  const { mutateAsync: updateOrderData, isPending } = useEditShipOrder(id!, {
    onSuccess: () => {
      setTimeout(() => {
        const currentPath = window.location.pathname;
        window.location.href = currentPath + '?tab=items-to-ship';
      }, 1000);
    },
  });

  const updateOrderDetails = async (payload: any) => {
    if (
      !payload?.advanceWarehouseDate?.startDate ||
      !payload?.advanceWarehouseDate?.endDate
    ) {
      payload.advanceWarehouseDate = undefined;
    } else {
      payload.advanceWarehouseDate.startDate = toApiPayloadDateFormat(
        payload.advanceWarehouseDate.startDate,
      );
      payload.advanceWarehouseDate.endDate = toApiPayloadDateFormat(
        payload.advanceWarehouseDate.endDate,
      );
    }

    payload.accountInfo.deliveryAt = payload.accountInfo.deliveryAt
      ? toApiPayloadDateFormat(payload.accountInfo.deliveryAt)
      : null;
    payload.arrivalDate = toApiPayloadDateFormat(payload.arrivalDate ?? '');
    payload.pickupDate = toApiPayloadDateFormat(payload.pickupDate ?? '');
    payload.prepByDate = payload.prepByDate
      ? toApiPayloadDateFormat(payload.prepByDate)
      : null;
    payload.returnDate = toApiPayloadDateFormat(payload.returnDate ?? '');
    payload.shipDate = payload.shipDate
      ? toApiPayloadDateFormat(payload.shipDate)
      : undefined;
    if (typeof payload.isReturnable === 'string')
      payload.isReturnable = payload.isReturnable === 'true' ? true : false;
    const _orderDetail = payload.orderDetail.map((i: any) => {
      const variant = i.variant as any;
      const variantId = variant._id;
      return {
        item: i.item?._id,
        price: i.price,
        quantity: i.quantity,
        variant: typeof variant === 'object' ? variantId : variant,
      };
    });
    payload.returnDate = payload.returnDate
      ? toApiPayloadDateFormat(payload.returnDate ?? '')
      : undefined;
    if (typeof payload.isReturnable === 'string')
      payload.isReturnable = payload.isReturnable === 'true';
    if (typeof payload.isClientBillToAccount === 'string')
      payload.isClientBillToAccount =
        payload.isClientBillToAccount === 'true' ? true : false;
    payload.orderDetail = _orderDetail;

    if (id) {
      await updateOrderData(payload);
    }
  };

  const formik = useFormikContext();

  const handleCloseModal = () => {
    updateOrderDetails(formik.values);
    setModalOpen(false);
  };

  return (
    <div className="flex flex-col justify-start gap-3">
      <h3>Items To Ship For {project}</h3>
      <h5 className="text-primary-100">
        These are the items to ship for this project, feel free to add anything
        else that is needed
      </h5>
      <div>
        {mode !== 'ship' && (
          <SearchInput
            onSelect={onSearchResultSelect}
            searchFn={(searchValue: string) =>
              fetchItems({ search: searchValue, clientId, isArchived: false })
            }
          />
        )}
      </div>
      <div className="flex flex-wrap items-center">
        <div className="mr-4 mb-4">
          <label className="block mb-1">Filter by</label>
        </div>
        <div className="flex flex-wrap">
          <select
            name="exhibitMaterial"
            className="select select-sm select-bordered text-secondary max-w-xs mb-2 mr-4">
            <option value="">Exhibit Material </option>
          </select>
          <select
            name="graphics"
            className="select select-sm select-bordered text-secondary max-w-xs mb-2 mr-4">
            <option value="">Graphics</option>
          </select>
          <select
            name="giveaways"
            className="select select-sm select-bordered text-secondary max-w-xs mb-2 mr-4">
            <option value="">Giveaways</option>
          </select>
          <select
            name="productDisplay"
            className="select select-sm select-bordered text-secondary max-w-xs mb-2 mr-4">
            <option value="">Product Display</option>
          </select>
          <select
            name="portables"
            className="select select-sm select-bordered text-secondary max-w-xs mb-2 mr-4">
            <option value="">Portables</option>
          </select>
          <select
            name="fullfillment"
            className="select select-sm select-bordered text-secondary max-w-xs mb-2 mr-4">
            <option value="">Fulfillment</option>
          </select>
          <select
            name="showServices"
            className="select select-sm select-bordered text-secondary max-w-xs mb-2 mr-4">
            <option value="">Show Services</option>
          </select>
        </div>
      </div>
      <div>
        <div className="flex flex-col w-full font-sans text-primary-350">
          {meta.error && meta.touched && (
            <Text className="text-lg font-bold">{meta.error}</Text>
          )}
          {orderItems
            .slice()
            .sort(sortOrderItems)
            .map((value) => {
              const imageSrc =
                value?.item?.images && value.item.images.length > 0
                  ? `${process.env.REACT_APP_STORAGE_URL}/${value.item.images[0]}`
                  : '';
              const variantId = value?.variant?.toString();
              const inventories =
                value.item?.inventories?.filter(
                  (inv) => inv?.variant == variantId,
                ) || [];
              const availableQty =
                inventories.length > 0
                  ? inventories[0].quantity
                  : (value.inventory?.quantity ?? 0);
              const remainingQty =
                availableQty - (value.quantity ? value.quantity : 0);

              const disableErrors = isProcessed;

              const showErrorBorder = disableErrors ? false : remainingQty < 0;

              const renderErrorMessage = () => {
                if (disableErrors) {
                  return '';
                }
                if (remainingQty >= 0) {
                  return `Remaining Quantity: ${remainingQty}`;
                }
                return `Exceeded Quantity by ${Math.abs(remainingQty)}`;
              };

              return (
                <div
                  key={value?.item?._id}
                  className={`rounded-md border-b-2 border-color-gray flex items-center ${
                    showErrorBorder ? 'bg-red-50 text-red-600' : ''
                  }`}>
                  <div className="avatar placeholder mr-5 px-4">
                    <div className="bg-slate-300 text-neutral-content rounded w-10">
                      <ZoomableImage src={imageSrc} />
                    </div>
                  </div>
                  <div className="w-2/5">
                    <Text className="text-lg">{value?.item?.name}</Text>
                  </div>
                  <div className="w-1/5 flex flex-row gap-4 items-center">
                    <Text className="text-lg">{renderErrorMessage()}</Text>
                    {!disableErrors && remainingQty < 0 && status !== -1 && (
                      <button
                        onClick={(e) => handleOpenModal(e, value.item?._id)}
                        className="btn btn-xs">
                        Adjust
                      </button>
                    )}
                  </div>
                  <div className="w-1/6">
                    <StatusBadge
                      status={value?.item?.code || ''}
                      className="font-mono uppercase"
                    />
                  </div>
                  <div className="flex items-center w-1/6">
                    <Text className="text-lg">Quantity</Text>
                    <Input
                      disabled={mode === 'ship'}
                      label=""
                      placeholder=""
                      type="number"
                      name={`quantity-${value.item?._id}`}
                      value={value.quantity}
                      inputContainer="pl-2 pr-0"
                      onChange={(qty) => {
                        handleQuantityChange(value.item?._id!, parseInt(qty));
                      }}
                      className="w-14 mx-2 mb-1"
                    />
                    {remainingQty < 0 && !isProcessed && (
                      <img
                        src={alertIcon}
                        alt=""
                        className="w-5 animate-pulse"
                      />
                    )}
                    {(status === OrderStatus.CREATED ||
                      status === OrderStatus.BEING_CREATED) && (
                      <div
                        className="px-2"
                        onClick={() => handleDelete(value.item?._id!)}>
                        <Delete />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          {isModalOpen && (
            <div className="modal modal-open">
              <div className="modal-box">
                <div className="modal-action flex flex-col items-center">
                  <button
                    type="button"
                    onClick={() => setModalOpen(false)}
                    className="absolute top-0 right-0 m-4">
                    <CloseIcon />
                  </button>
                  <AdjustStockModalContainer
                    itemId={String(selectedItemId)}
                    onSuccess={handleCloseModal}
                  />
                  <button
                    onClick={() => setModalOpen(false)}
                    className="btn w-1/2 my-4">
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemsToShipView;
